<template>
  <div>
    <van-overlay :show="isLoading" zIndex="2">
      <van-loading color="#1989fa" size="64" class="txt-c" />
    </van-overlay>

    <van-nav-bar :title="titlePage" :border="false">
      <template #right>
        <router-link tag="div" to="/setting" class="page-title-btn">
          <img src="@/assets/images/settings.svg" alt="" srcset="" />
        </router-link>
      </template>
    </van-nav-bar>

    <div
      class="task-list"
      :class="{
        bottomSpace: !bottomSpace,
      }"
    >
      <template v-if="tasksList.length">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text=""
          :loading-text="$t('btn.loading')"
          @load="onLoad"
        >
          <div
            class="task-list-item"
            v-for="(item, key) in tasksList"
            :key="key"
          >
            <div class="task-list-item__link" @click="doTo(item)">
              <p class="task-list-item__text">
                {{ getHortText(item.text) }}
              </p>

              <div v-if="item.attachments">
                <van-image
                  width="96"
                  height="96"
                  fit="contain"
                  v-for="(elem, key) in item.attachments"
                  :key="key"
                  :src="elem"
                  @click="show = true"
                />
              </div>
            </div>
            <div class="btn-row" tabindex="2" v-if="item.id_category == 0">
              <van-button type="primary" tabindex="3" @click="accept(item)">
                {{ $t("btn.accept") }}
              </van-button>

              <van-button
                plain
                type="primary"
                tabindex="4"
                @click="deleteTask(item)"
              >
                {{ $t("btn.delete") }}
              </van-button>
            </div>
          </div>
        </van-list>
      </template>
      <van-empty description="" v-else />
    </div>

    <van-button
      type="warning"
      class="btn more-btn"
      v-if="bottomSpace && params.type == 'new'"
      @click="showComplite()"
    >
      {{ $t("btn.complited", { count: choosenCategory.tasks_done }) }}
    </van-button>
    <van-button
      type="warning"
      class="btn more-btn"
      v-if="bottomSpace && params.type != 'new'"
      @click="showNew()"
    >
      {{ $t("btn.new_task", { count: choosenCategory.tasks }) }}
    </van-button>

    <CatagoryCreate :isCreate="isCreate" @close="getListTask()" />

    <!-- <van-popup v-model="isChooseCategory" position="bottom"> -->
    <van-dialog
      v-model="isChooseCategory"
      closeOnClickOverlay
      :showConfirmButton="false"
    >
      <ChooseCategory
        v-if="isChooseCategory"
        :isCategory="isChooseCategory"
        :task_id="choosenTask.id"
        @close="setCategory()"
      />
    </van-dialog>

    <van-dialog
      v-model="showPopover"
      closeOnClickOverlay
      :showConfirmButton="false"
      width="560px"
    >
      <Task @closePopup="showPopover = false" :is-modal="true" />
    </van-dialog>
  </div>
</template>

<script>
// import ApiCategory from "@/api/category";
import ApiTasks from "@/api/tasks";

import { mapActions, mapGetters } from "vuex";
import CatagoryCreate from "@/components/Category/Create";
import ChooseCategory from "@/components/Task/ChooseCategory";
import Task from "@/views/Task";
import { eventBus } from "@/main";

// import _ from "lodash";
export default {
  data() {
    return {
      showPopover: false,

      isLoading: true,
      isChooseCategory: false,
      isCreate: false,
      loading: false,
      finished: false,
      active: 0,
      choosenTask: {},
      params: {
        limit: 10,
        offset: 0,
        id_category: 0,
        type: "new",
      },
    };
  },
  components: {
    CatagoryCreate,
    ChooseCategory,
    Task,
  },
  computed: {
    ...mapGetters("Category", ["choosenCategory", "categoryList"]),
    ...mapGetters("Task", ["taskList"]),
    bottomSpace() {
      return (
        this.choosenCategory.id != 0 && this.choosenCategory.tasks_done != 0
      );
    },
    titlePage() {
      return this.choosenCategory.id == 0
        ? `${this.choosenCategory.title} (${this.choosenCategory.tasks})`
        : this.choosenCategory.title;
    },
  },
  watch: {
    choosenCategory() {
      this.featchListTask();
    },
  },
  methods: {
    ...mapActions("Task", ["setTask", "getListTask"]),

    showNew() {
      this.params.limit = 10;
      this.getListTask("new");
    },
    showComplite() {
      if (this.choosenCategory.tasks_done != 0) {
        this.params.limit = 10;
        this.getListTask("done");
      }
    },
    getHortText(text) {
      return text.length > 100 ? text.slice(0, 100) + "..." : text;
    },
    onLoad() {
      this.params.limit += 10;
      ApiTasks.getTasksByCategory(this.params).then((responce) => {
        this.tasksList = responce.data;
        this.loading = false;
        if (this.params.type == "new") {
          if (responce.data.length == this.choosenCategory.tasks) {
            this.finished = true;
          }
        } else {
          if (responce.data.length == this.choosenCategory.tasks_done) {
            this.finished = true;
          }
        }
      });
    },
    featchListTask(type = "new") {
      this.params.type = type;
      this.params.id_category = this.choosenCategory.id;
      this.isLoading = true;
      ApiTasks.getTasksByCategory(this.params).then((responce) => {
        this.tasksList = responce.data;
        this.isLoading = false;
      });
    },
    doTo(item) {
      console.log(item);

      this.setTask(item).then(() => {
        this.showPopover = true;
      });
    },
    setCategory() {
      this.isChooseCategory = false;
      this.featchListTask();
    },
    accept(item) {
      if (this.categoryList.length > 1) {
        this.choosenTask = item;
        this.isChooseCategory = true;
      } else {
        this.isCreate = true;
      }
    },
    deleteTask(item) {
      this.$dialog
        .confirm({
          cancelButtonText: this.$t("category.no"),
          confirmButtonText: this.$t("category.yes"),
          closeOnClickOverlay: true,
          message: this.$t("category.confirm_delete"),
        })
        .then(() => {
          ApiTasks.deleteTask(item.id).then(() => {
            this.featchListTask();
          });
        });
    },
  },
  mounted() {
    this.featchListTask();
    eventBus.$on("chooseCategory", () => {
      this.featchListTask();
    });

    eventBus.$on("doneTask", () => {
      this.featchListTask();
    });

    eventBus.$on("editTask", () => {
      this.featchListTask();
    });
  },
};
</script>

<style lang="scss" scoped>
.task-list {
  .task-list-item {
    padding: 16px;
    background: #f9f8f9;
    border-radius: 12px;
    margin-bottom: 20px;
    cursor: pointer;
    display: block;
    color: #111;
    &__text {
      font-weight: 300;
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 12px;
    }
  }
}

.more-btn {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}
.more-btn,
.bottomSpace {
  margin-bottom: 82px;
}
</style>
